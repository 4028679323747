<template>
	<div>
		<!--section banner-->
		<section class="service_banner marginTop_fixed color1">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>
							<svg
								width="37"
								height="39"
								viewBox="0 0 37 39"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M34.5198 11.8502L33.365 9.85995C32.3881 8.17587 30.223 7.5949 28.5251 8.56121C27.7169 9.03409 26.7525 9.16826 25.8445 8.93411C24.9366 8.69997 24.1597 8.11675 23.6852 7.31306C23.3799 6.80222 23.2159 6.22038 23.2097 5.62637C23.2372 4.67403 22.8755 3.75117 22.2069 3.06802C21.5382 2.38487 20.6197 1.99961 19.6604 2H17.3339C16.3941 2 15.4931 2.37193 14.8302 3.03352C14.1673 3.6951 13.7971 4.59179 13.8016 5.52517C13.7738 7.45228 12.1928 8.99994 10.2524 8.99974C9.65429 8.99358 9.06848 8.83066 8.55415 8.52747C6.85623 7.56117 4.6912 8.14213 3.71424 9.82622L2.47455 11.8502C1.49878 13.5322 2.07576 15.6812 3.76519 16.6573C4.86335 17.287 5.53984 18.4508 5.53984 19.7102C5.53984 20.9696 4.86335 22.1334 3.76519 22.7631C2.07791 23.7326 1.5003 25.8764 2.47455 27.5533L3.64632 29.5604C4.10406 30.3808 4.87207 30.9861 5.7804 31.2425C6.68874 31.4989 7.6625 31.3853 8.48622 30.9267C9.29598 30.4574 10.2609 30.3288 11.1666 30.5695C12.0723 30.8102 12.8436 31.4003 13.3091 32.2085C13.6144 32.7194 13.7784 33.3012 13.7846 33.8952C13.7846 35.8421 15.3737 37.4204 17.3339 37.4204H19.6604C21.614 37.4204 23.2004 35.8524 23.2097 33.9121C23.2052 32.9758 23.5776 32.0765 24.2442 31.4144C24.9108 30.7524 25.8163 30.3824 26.759 30.3869C27.3556 30.4028 27.939 30.565 28.4572 30.8592C30.1507 31.8283 32.3143 31.2553 33.2971 29.5773L34.5198 27.5533C34.9931 26.7464 35.123 25.7855 34.8807 24.8832C34.6385 23.981 34.0441 23.2119 33.2291 22.7462C32.4142 22.2806 31.8198 21.5115 31.5776 20.6092C31.3353 19.707 31.4652 18.746 31.9385 17.9392C32.2463 17.4055 32.6918 16.963 33.2291 16.6573C34.9085 15.6817 35.4841 13.5453 34.5198 11.8671V11.8502Z"
									stroke="#347362"
									stroke-width="2.25"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M18.5051 24.5698C21.2062 24.5698 23.396 22.395 23.396 19.7121C23.396 17.0293 21.2062 14.8545 18.5051 14.8545C15.804 14.8545 13.6143 17.0293 13.6143 19.7121C13.6143 22.395 15.804 24.5698 18.5051 24.5698Z"
									stroke="#347362"
									stroke-width="2.25"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							Technical Analysis
						</h1>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--image start-->
		<section class="imageBanner">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="imgbanner">
							<img src="@/assets/images/img-11.jpg" alt="" />
							<div class="playvideo">
								<a
									href="https://www.youtube.com/watch?v=s8SrerotCV8"
									data-fancybox
								>
									<img src="@/assets/images/play.svg" alt="" /> Play video
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--main section left image-->
		<section class="contentservices">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6">
						<div class="imgservices1">
							<img src="@/assets/images/img-12.png" alt="" />
						</div>
					</div>
					<div class="col-md-6">
						<div class="contentSer">
							<h3>
								Improve the performance of your website by identifying and
								examining bugs and errors
							</h3>
							<p>
								Do in-depth and easy technical analysis of your site, page by
								page, improving the performance of your website by examining
								each element easily.
							</p>
							<p>
								SEO Suite’s technical analysis provides clear and actionable
								advice on steps to take to improve your online presence and rank
								better in search engine results.
							</p>
							<p>
								It is ideal for website owners, website designers, and digital
								agencies who want to improve their own sites or those of their
								clients. <br />SEO Suite expertly analyzes how to fix your
								site's SEO issues without technical skills.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--main section Right  image-->
		<section class="contentservices">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-12">
						<h2>
							<span>Professional</span> analysis in just a few
							<span>simple</span> clicks
						</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 order-md-first order-last">
						<div class="contentSer">
							<h3>Check for multiple issues <span>at once</span></h3>
							<p>
								It automatically shows you the potential impact for each
								problem, so you can troubleshoot the issue based on how much it
								might affect your rank and how easy it is to implement the
								necessary changes
							</p>
							<ul>
								<li>Webmaster site errors</li>
								<li>Bad URLs</li>
								<li>Crawl/Index issues</li>
								<li>Sitemap issues</li>
							</ul>
							<p>
								Reduce risks and increase efficiency on your website with our
								SEO Suite tool. Our in-depth analysis helps you understand the
								technical flaws on your site, from broken links to missing meta
								tags, so you can improve the performance.
							</p>
						</div>
					</div>
					<div class="col-md-6 order-md-last order-first">
						<div class="imgservices2 extrimgsize">
							<img src="@/assets/images/img-13.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--Services Acc-->
		<section class="servicesAccording">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-12">
						<h3>What Brand Reputation includes?</h3>

						<div class="accordion faqsc" role="tablist">
							<b-card no-body v-for="(items, index) in faqs" :key="items.index">
								<b-card-header header-tag="header" role="tab">
									<b-button
										block
										v-b-toggle="'accordion' + index"
										variant="info"
									>
										{{ items.faqQuestion }}
									</b-button>
								</b-card-header>
								<b-collapse
									:id="'accordion' + index"
									visible
									accordion="my-accordion"
									role="tabpanel"
								>
									<b-card-body>
										<p class="card-text" v-html="items.faqAnswer"></p>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--END-->

		<!--Faq services start-->
		<section class="servicesFaq">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-12">
						<h3>FAQ for Technical Analysis</h3>

						<h4>What is Technical Analysis?</h4>
						<p>
							Ultrices tincidunt arcu non sodales neque sodales ut etiam.
							Habitant morbi tristique senectus et netus et. Lacus sed viverra
							tellus in hac habitasse. Sem viverra aliquet eget sit amet. Lorem
							ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Tincidunt nunc
							pulvinar sapien et. Egestas quis ipsum suspendisse ultrices
							gravida dictum fusce ut.
						</p>
						<h4>How is Technical Analysis?</h4>
						<p>
							Sem viverra aliquet eget sit amet. Lorem ipsum dolor sit amet,
							consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Tincidunt nunc pulvinar sapien et.
							Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut.
						</p>
						<h4>Where is Technical Analysis?</h4>
						<p>
							Tincidunt nunc pulvinar sapien et. Egestas quis ipsum suspendisse
							ultrices gravida dictum fusce ut. Sem viverra aliquet eget sit
							amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
							do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ultrices tincidunt arcu non sodales neque sodales ut etiam.
							Habitant morbi tristique senectus et netus et. Lacus sed viverra
							tellus in hac habitasse.
						</p>
					</div>
				</div>
			</div>
		</section>
		<!--end Faq-->

		<!--services hotspots-->
		<section class="servicesFivespots">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h3 class="text-center">Learn about other pillars!</h3>
						<ul class="spotsservices">
							<li class="scolor1">
								<router-link :to="`/${$i18n.locale}/content-analysis`">
									<svg
										width="34"
										height="40"
										viewBox="0 0 34 40"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.5 8.5625H24.5M9.5 14.1875H24.5M9.5 19.8125H17.9375M27.3125 24.5H22.625M17.9375 24.5H22.625M22.625 24.5V32.9375M32 5.75V33.875C32 35.9461 30.3211 37.625 28.25 37.625H5.75C3.67893 37.625 2 35.9461 2 33.875V5.75C2 3.67893 3.67893 2 5.75 2H28.25C30.3211 2 32 3.67893 32 5.75Z"
											stroke="#F5F3EE"
											stroke-width="2.8125"
											stroke-linejoin="round"
										/>
									</svg>
									<h4>Content Analysis</h4></router-link
								>
							</li>
							<li class="scolor2">
								<router-link :to="`/${$i18n.locale}/content-generator`">
									<svg
										width="35"
										height="35"
										viewBox="0 0 35 35"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clip-path="url(#clip0_1_4377)">
											<path
												d="M33.0051 6.10481L33.0065 6.10618C33.7157 6.81349 33.7171 7.94687 32.9691 8.65912L32.9563 8.67138L32.9437 8.68395L28.1677 13.4594L21.5341 6.77889L26.3156 1.99629L26.3158 1.99613C27.0271 1.28443 28.1844 1.28403 28.8968 1.99641L33.0051 6.10481ZM3.23541 25.0833L17.9341 10.3846L24.6177 17.075L9.91813 31.7746L1.56295 33.4456L3.23541 25.0833ZM10.026 31.753C10.0261 31.753 10.0262 31.753 10.0262 31.753L10.026 31.753ZM3.15781 25.1609C3.15767 25.1611 3.15753 25.1612 3.15738 25.1614L2.9002 24.9042L3.15739 25.1613L3.15781 25.1609Z"
												stroke="#F5F3EE"
												stroke-width="2.91667"
											/>
											<path
												d="M15.5557 34.0273H35.0001"
												stroke="#F5F3EE"
												stroke-width="2.91667"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1_4377">
												<rect width="35" height="35" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<h4>Content Generator</h4></router-link
								>
							</li>
							<li class="scolor3">
								<router-link :to="`/${$i18n.locale}/project-management`">
									<svg
										width="37"
										height="39"
										viewBox="0 0 37 39"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M27.25 15.125L18.9348 19.2826C18.6611 19.4195 18.3389 19.4195 18.0652 19.2826L9.75 15.125M27.25 15.125L34.2608 11.6196C34.9774 11.2613 34.9774 10.2387 34.2608 9.88042L18.9348 2.2174C18.6611 2.08054 18.3389 2.08054 18.0652 2.2174L2.73916 9.88042C2.02259 10.2387 2.02259 11.2613 2.73916 11.6196L9.75 15.125M27.25 15.125L34.2608 18.6304C34.9774 18.9887 34.9774 20.0113 34.2608 20.3696L27.25 23.875M9.75 15.125L2.73916 18.6304C2.02259 18.9887 2.02259 20.0113 2.73916 20.3696L9.75 23.875M27.25 23.875L18.9348 28.0326C18.6611 28.1695 18.3389 28.1695 18.0652 28.0326L9.75 23.875M27.25 23.875L34.2608 27.3804C34.9774 27.7387 34.9774 28.7613 34.2608 29.1196L18.9348 36.7826C18.6611 36.9195 18.3389 36.9195 18.0652 36.7826L2.73916 29.1196C2.02259 28.7613 2.02259 27.7387 2.73916 27.3804L9.75 23.875"
											stroke="#F5F3EE"
											stroke-width="2.91667"
										/>
									</svg>
									<h4>Project Management</h4></router-link
								>
							</li>
							<li class="scolor4">
								<router-link :to="`/${$i18n.locale}/brand-reputation`">
									<svg
										width="37"
										height="37"
										viewBox="0 0 37 37"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="6.125"
											cy="26.75"
											r="4.125"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<circle
											r="4.125"
											transform="matrix(1 0 0 -1 30.875 26.75)"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<circle
											r="4.125"
											transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 18.5 6.125)"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<path
											d="M12.3125 32.9375C12.3125 32.9375 15.0768 35 18.5 35C21.9232 35 24.6875 32.9375 24.6875 32.9375"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
										<path
											d="M30.875 19.5312C30.875 19.5312 30.875 17.4688 29.5312 14.7787C28.1873 12.0886 25.7188 10.25 25.7188 10.25"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
										<path
											d="M6.125 19.5312C6.125 19.5312 6.125 17.4688 7.46883 14.7787C8.81265 12.0886 11.2812 10.25 11.2812 10.25"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
									</svg>

									<h4>Brand Reputation</h4></router-link
								>
							</li>
							<li class="scolor5">
								<router-link :to="`/${$i18n.locale}/crm`">
									<svg
										width="49"
										height="39"
										viewBox="0 0 49 39"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2 32V7C2 4.23858 4.23858 2 7 2H42C44.7614 2 47 4.23858 47 7V32C47 34.7614 44.7614 37 42 37H7C4.23858 37 2 34.7614 2 32Z"
											stroke="#F5F3EE"
											stroke-width="3.75"
											stroke-linejoin="round"
										/>
										<path d="M2 12H47" stroke="#F5F3EE" stroke-width="3.75" />
										<path
											d="M9.5 19.5L9.5 22.625L9.5 32"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
										<path
											d="M42 22L26.375 22L17 22"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
										<path
											d="M42 29.5L26.375 29.5L17 29.5"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
									</svg>
									<h4>CRM</h4></router-link
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!--end-->
		<ServiceCallAction />
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";

	import eng from "../locales/technical_analysis_faq_en.json";
	import ita from "../locales/technical_analysis_faq_it.json";

	import ServiceCallAction from "@/components/Services_Call_Actions.vue";

	export default {
		name: "TechnicalAnalysis",
		components: {
			ServiceCallAction,
		},
		data: () => ({
			eng: eng,
			ita: ita,
		}),
		computed: {
			faqs() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},

		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
